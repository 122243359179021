const setDropDepth = (num) => {
  return {
    type: 'SET_DROP_DEPTH',
    dropDepth: num,
  };
};

const setInDropzone = (tf) => {
  return {
    type: 'SET_IN_DROP_ZONE',
    inDropZone: tf,
  };
};

const setUploadStatus = (tf) => {
  return {
    type: 'SET_UPLOAD_STATUS',
    uploadDone: tf,
  };
};

export default {
  setDropDepth,
  setInDropzone,
  setUploadStatus,
};
