import React from 'react';
import { Alert, Modal } from "react-bootstrap";

const SSOLogin = () => {
    return (
        <Modal show={true} style={{ paddingTop: "150px", backdropFilter: "blur(5px)" }}>
            <Alert variant={'light'} style={{ margin: "0px", textAlign: "center" }}>
                <p><b>Please do not close this window.</b></p>
                <p><b>You are being redirected to MiHIN's MIGateway Patient Viewer System.</b></p>
            </Alert>
        </Modal>
    );
};

export default SSOLogin;