const setDuplicateOrgs = (duplicateOrgs) => {
  return {
    type: 'SET_DUPLICATE_ORGS',
    duplicateOrgs: duplicateOrgs,
  };
};

const addDuplicateOrgs = (duplicateOrgs) => {
  return {
    type: 'ADD_DUPLICATE_ORGS',
    duplicateOrgs: duplicateOrgs,
  };
};

const resetDuplicateOrgs = () => {
  return {
    duplicateOrgs: [],
  };
};

const handleConfirm = (tf) => {
  return {
    isConfirming: tf,
  };
};

export default {
  setDuplicateOrgs,
  addDuplicateOrgs,
  resetDuplicateOrgs,
  handleConfirm,
};
