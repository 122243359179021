const setActiveTab = (tab) => {
  return {
    type: 'SET_ACTIVE_TAB',
    activeTab: tab,
  };
};

const setGivenName = (name) => {
  return {
    type: 'SET_GIVEN_NAME',
    payload: name,
  };
};

const setEmail = (email) => {
  return {
    type: 'SET_EMAIL',
    payload: email,
  };
};

const setSub = (sub) => {
  return {
    type: 'SET_SUB',
    payload: sub,
  };
};

const setInitialLogin = (bool) => {
  return {
    type: 'SET_INITIAL_LOGIN',
    payload: bool,
  };
};

export default {
  setActiveTab,
  setGivenName,
  setEmail,
  setSub,
  setInitialLogin
};
