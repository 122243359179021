const errors = (
  state = {
    errorList: [],
    dupeFiles: [],
  },
  action
) => {
  switch (action.type) {
    case 'ADD_ERROR_OBJECT':
      return {
        ...state,
        errorList: [...state.errorList, action.errorObject],
        numFiles: state.numFiles + 1,
        filesValidated: state.filesValidated + 1,
      };
    case 'CLEAR_ERRORS':
      return {
        ...state,
        errorList: [],
      };
    case 'SET_DUPE_FILES':
      return {
        ...state,
        dupeFiles: action.payload,
      };
    default:
      return state;
  }
};

export default errors;
