const setShowSuccess = (tf) => {
  return {
    type: 'SET_SHOW_SUCCESS',
    payload: tf,
  };
};

const setSuccessMessage = (message) => {
  return {
    type: 'SET_SUCCESS_MESSAGE',
    payload: message,
  };
};

export default {
  setShowSuccess,
  setSuccessMessage,
};
