import addUserActions from './addUserActions';
import allAccessActions from './allAccessActions';
import dropzoneActions from './dropzoneActions';
import editUserActions from './editUserActions';
import errorActions from './errorActions';
import errorHandlingActions from './errorHandlingActions';
import fileObjectActions from './fileObjectActions';
import loginActions from './loginActions';
import modalActions from './modalActions';
import orgAdminActions from './orgAdminActions';
import patientContextActions from './patientContextActions';
import permissionsActions from './permissionsActions';
import successActions from './successActions';
import tokenActions from './tokenActions';
import viprActions from './viprActions';
import userActions from './userActions';

const allActions = {
  addUserActions,
  allAccessActions,
  dropzoneActions,
  editUserActions,
  errorActions,
  errorHandlingActions,
  fileObjectActions,
  loginActions,
  modalActions,
  orgAdminActions,
  patientContextActions,
  permissionsActions,
  successActions,
  tokenActions,
  viprActions,
  userActions,
};

export default allActions;
