const modals = (
  state = {
    showAddUserModal: false,
    showEditUserModal: false,
    isLoadingModal: false,
    errorType: null,
    org: null,
    showEditOrgModal: false,
    showConfirmationModal: false,
  },
  action
) => {
  switch (action.type) {
    case 'SET_SHOW_ADD_USER_MODAL':
      return {
        ...state,
        showAddUserModal: action.payload,
      };
    case 'SET_SHOW_EDIT_USER_MODAL':
      return {
        ...state,
        showEditUserModal: action.payload,
      };
    case 'SET_IS_LOADING_MODAL':
      return {
        ...state,
        isLoadingModal: action.isLoadingModal,
      };
    case 'SET_SHOW_ERROR_MODAL':
      return {
        ...state,
        showErrorModal: action.showErrorModal,
        errorType: action.errorType,
      };
    case 'SET_SHOW_SUCCESS_MODAL':
      return {
        ...state,
        showSuccessModal: action.showSuccessModal,
      };
    case 'SET_SHOW_BULK_ADD_PRACTICES_MODAL':
      return {
        ...state,
        showBulkAddPracticesModal: action.showBulkAddPracticesModal,
      };
    case 'SET_SHOW_EDIT_ORG_MODAL':
      return {
        ...state,
        showEditOrgModal: action.showEditOrgModal,
        org: action.org,
      };
    case 'SET_SHOW_CONFIRMATION_MODAL':
      return {
        ...state,
        showConfirmationModal: action.showConfirmationModal,
      };
    default:
      return state;
  }
};

export default modals;
