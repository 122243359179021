const addErrorObject = (obj) => {
  return {
    type: 'ADD_ERROR_OBJECT',
    errorObject: obj,
  };
};

const clearErrors = () => {
  return {
    type: 'CLEAR_ERRORS',
  };
};

const setDupeFiles = (files) => {
  return {
    type: 'SET_DUPE_FILES',
    payload: files,
  };
};

export default {
  addErrorObject,
  clearErrors,
  setDupeFiles,
};
