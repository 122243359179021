import { v4 as uuidv4 } from 'uuid';

const fileObjects = (
  state = {
    fileObjects: [],
    numFiles: 0,
    filesValidated: 0,
    transactionId: uuidv4(),
    count: 0,
    isValidating: false,
    lastFile: false,
  },
  action
) => {
  switch (action.type) {
    case 'ADD_FILE_OBJECT':
      return {
        ...state,
        fileObjects: state.fileObjects.concat(action.payload),
        filesValidated: state.filesValidated + 1,
        numFiles: state.numFiles + 1,
      };
    case 'REMOVE_FILE_OBJECT':
      return {
        ...state,
        fileObjects: state.fileObjects.filter(
          (_, index) => index !== action.payload
        ),
        numFiles: state.numFiles - 1,
        filesValidated: state.filesValidated - 1,
      };
    case 'UPDATE_DESCRIPTION':
      return {
        ...state,
        fileObjects: state.fileObjects.filter((obj, index) => {
          if (index === action.payload.index) {
            obj.description = action.payload.description;
          }
          return obj;
        }),
      };
    case 'UPDATE_FILE_PROGRESS':
      return {
        ...state,
        fileObjects: state.fileObjects.filter((obj, index) => {
          if (index === action.payload.index) {
            obj.fileProgress = action.payload.fileProgress;
          }
          return obj;
        }),
      };
    case 'CLEAR_FILE_OBJECTS':
      return {
        ...state,
        fileObjects: [],
        numFiles: 0,
        transactionId: uuidv4(),
        count: 0,
        filesValidated: 0,
      };
    case 'SET_LAST_FILE':
      return {
        ...state,
        lastFile: action.lastFile,
      };
    default:
      return state;
  }
};

export default fileObjects;
