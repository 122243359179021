import { useReducer, useState, useEffect } from "react";
import { Auth, Hub } from "aws-amplify";
import { useDispatch, useSelector } from "react-redux";
import allActions from "../state/actions";
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import qs from 'qs'

const now = Date.now();

const amplifyAuthReducer = (state, action) => {
  switch (action.type) {
    case "FETCH_USER_DATA_INIT":
      return {
        ...state,
        isLoading: true,
        isError: false,
      };
    case "FETCH_USER_DATA_SUCCESS":
      return {
        ...state,
        isLoading: false,
        isError: false,
        isLoggedIn: true,
        user: action.payload.user,
      };
    case "FETCH_USER_DATA_FAILURE":
      return { ...state, isLoading: false, isError: true, isLoggedIn: false };
    case "RESET_USER_DATA":
      return { ...state, user: null, isLoggedIn: false };
    case "SET_IS_LOGGED_IN":
      return { ...state, isLoggedIn: action.isLoggedIn };
    case "SET_ORG_NAME":
      return { ...state };
    default:
      throw new Error();
  }
};

const useAmplifyAuth = (props) => {
  const initialState = {
    isLoading: true,
    isError: false,
    user: null,
  };
  const [state, dispatch] = useReducer(amplifyAuthReducer, initialState);
  const [triggerFetch, setTriggerFetch] = useState(false);
  const signOutDispatch = useDispatch();
  const refreshToken = useSelector((state) => state.tokens.refreshToken);

  useEffect(() => {
    let isMounted = true;
    const fetchUserData = async () => {
      if (isMounted) {
        dispatch({ type: "FETCH_USER_DATA_INIT" });
      }
      try {
        if (isMounted) {
          const data = await Auth.currentAuthenticatedUser({
            bypassCache: true,
          });
          if (data) {
            dispatch({
              type: "FETCH_USER_DATA_SUCCESS",
              payload: { user: data },
            });
          }
        }
      } catch (error) {
        if (isMounted) {
          dispatch({ type: "FETCH_USER_DATA_FAILURE" });
        }
      }
    };

    const HubListener = () => {
      Hub.listen("auth", (data) => {
        const { payload } = data;
        onAuthEvent(payload);
      });
    };

    const onAuthEvent = (payload) => {
      switch (payload.event) {
        case "signIn":
          if (isMounted) {
            setTriggerFetch(true);
          }
          break;
        default:
          return;
      }
    };

    HubListener();
    fetchUserData();

    return () => {
      Hub.remove("auth");
      isMounted = false;
    };
  }, [triggerFetch]);

  const handleSignout = async () => {
    try {
      sessionStorage.setItem("ssoLogin", false);
      await Auth.signOut();
      await revokeToken();
      setTriggerFetch(false);
      dispatch({ type: "RESET_USER_DATA" });
      signOutDispatch(allActions.loginActions.setShowLoginForm(true));
    } catch (error) {
      console.error("Error signing out user ", error);
    }
  };

  const revokeToken = async () => {
    const cognitoDomain = process.env.REACT_APP_COGNITO_DOMAIN
    let data = qs.stringify({
      'client_id': process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID,
      'token': refreshToken
    });
    const requestOptions = {
      url: cognitoDomain + '/oauth2/revoke',
      method: 'POST',
      headers: {
        'content-type': 'application/x-www-form-urlencoded'
      },
      data: data
    };
    await axios(requestOptions)
      .then((response) => {
        console.info("Successfully revoked token", response);
      })
      .catch((error) => {
        console.error("Error revoking token ", error);
      });
  };

  //send SSO users to the session expired page rather than login
  const history = useHistory();
  const handleSSOSignout = async () => {
    //refactoring so we don't need the modal component anymore
    let logoutTimeout;

    const setTimeouts = () => {
      logoutTimeout = setTimeout(logout, 600 * 1000);
    };

    const clearTimeouts = () => {
      if (logoutTimeout) clearTimeout(logoutTimeout);
    };

    const resetTimeout = () => {
      clearTimeouts();
      setTimeouts();
    };

    const events = [
      'load',
      'mousemove',
      'mousedown',
      'click',
      'scroll',
      'keypress',
    ];

    for (let i in events) {
      window.addEventListener(events[i], resetTimeout);
    }

    setTimeouts();

    const logout = async () => {
      //end session and redirect to session timed out page
      try {
        await Auth.signOut();
        await revokeToken();
        setTriggerFetch(false);
        dispatch({ type: "RESET_USER_DATA" });
        history.push('/ssoexpiredsession')
      } catch (error) {
        console.error("Error signing out sso user ", error);
      }
    }

  };

  return { state, handleSignout, dispatch, handleSSOSignout };
};

export default useAmplifyAuth;
