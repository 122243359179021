const setEditUserObject = (userObj) => {
  return {
    type: 'SET_EDIT_USER_OBJECT',
    payload: userObj,
  };
};

export default {
  setEditUserObject,
};
