const setIsAdmin = (tf) => {
  return {
    type: 'SET_IS_ADMIN',
    payload: tf,
  };
};

const setIsSuperAdmin = (tf) => {
  return {
    type: 'SET_IS_SUPER_ADMIN',
    payload: tf,
  };
};

const setIsOrgAdmin = (tf) => {
  return {
    type: 'SET_IS_ORG_ADMIN',
    payload: tf,
  };
};

const setIsDataSteward = (tf) => {
  return {
    type: 'SET_IS_DATA_STEWARD',
    payload: tf,
  };
};

const setPermissions = (permObj) => {
  return {
    type: 'SET_PERMISSIONS',
    payload: permObj,
  };
};

const setOrgName = (orgName) => {
  return {
    type: 'SET_ORG_NAME',
    payload: orgName,
  }
}

const setOrgId = (orgId) => {
  return {
    type: 'SET_ORG_ID',
    payload: orgId,
  }
}

const setLaunchId = (launchId) => {
  return {
    type: 'SET_LAUNCH_ID',
    payload: launchId,
  }
}

export default {
  setIsAdmin,
  setIsSuperAdmin,
  setIsOrgAdmin,
  setIsDataSteward,
  setPermissions,
  setOrgName,
  setOrgId,
  setLaunchId
};
