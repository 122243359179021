const user = (
  state = {
    activeTab: 'adtreports',
    givenName: '',
    email: '',
    sub: '',
    initialLogin: 'false'
  },
  action
) => {
  switch (action.type) {
    case 'SET_ACTIVE_TAB':
      return {
        ...state,
        activeTab: action.activeTab,
      };
    case 'SET_GIVEN_NAME':
      return {
        ...state,
        givenName: action.payload,
      };
    case 'SET_EMAIL':
      return {
        ...state,
        email: action.payload,
      };
    case 'SET_SUB':
      return {
        ...state,
        sub: action.payload,
      };
    case 'SET_INITIAL_LOGIN':
      return {
        ...state,
        initialLogin: action.payload,
      };
    default:
      return state;
  }
};

export default user;
