const dropzone = (
  state = {
    dropDepth: 0,
    inDropZone: false,
    uploadDone: false,
  },
  action
) => {
  switch (action.type) {
    case 'SET_DROP_DEPTH':
      return { ...state, dropDepth: action.dropDepth };
    case 'SET_IN_DROP_ZONE':
      return { ...state, inDropZone: action.inDropZone };
    case 'SET_UPLOAD_STATUS':
      return { ...state, uploadDone: action.uploadDone };
    default:
      return state;
  }
};

export default dropzone;
