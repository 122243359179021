import { useContext, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';

import Analytics from '@aws-amplify/analytics';

import { AuthContext } from '../context/AuthContext';

const useAnalytics = (page) => {
  const { user } = useContext(AuthContext);
  const isMountedRef = useRef(null);
  const email = useSelector((state) => state.user.email);
  const orgName = useSelector((state) => state.permissions.orgName);
  const orgId = useSelector((state) => state.permissions.orgId);

  useEffect(() => {
    isMountedRef.current = true;
    if (user) {
      Analytics.record({
        name: `${page} Visit`,
        attributes: {
          username: email,
          org_id: orgId,
          org_name: orgName,
        },
      });
    }
    return () => (isMountedRef.current = false);
  }, [user, page]);
};

export default useAnalytics;
