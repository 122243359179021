const addUser = (
  state = {
    addUserObject: {
      given_name: "",
      family_name: "",
      email: "",
      address: "",
      city: "",
      state: "",
      zip_code: "",
      orgId: "",
      orgName: "",
      role: "",
      initiallogin: false,
      accessTocViewer: false,
      accessManageAcrs: false,
      accessFileSub: false,
      accessConform: false,
      accessFallout: false,
      accessQmDash: false,
      accessEcms: false,
      accessVipr: false,
      accessSdoh: false,
      accessSolutionsCenter: false,
      accessHd: false,
      accessIqb: false,
      accessOrus: false,
      accessDiretto: false,
      accessHomePs: false,
      accessOperations: false,
      accessPatientOptOut: false,
      accessSdohReporting: false,
      accessOutcomesViewer: false,
      accessPatientSummary: false,
      dataSources: '',
      practiceOids: '',
      tocViewerPerm: '',
      manageAcrsPerm: '',
      providerNpis: '',
      consentForms: '',
      confGroupIds: []
    },
  },
  action
) => {
  switch (action.type) {
    case "SET_ADD_USER_OBJECT":
      return {
        ...state,
        addUserObject: action.payload,
      };
    case "RESET_ADD_USER_OBJECT":
      return {
        addUserObject: {
          given_name: "",
          family_name: "",
          email: "",
          address: "",
          city: "",
          state: "",
          zip_code: "",
          orgId: "",
          orgName: "",
          role: "",
          initiallogin: false,
          accessTocViewer: false,
          accessManageAcrs: false,
          accessFileSub: false,
          accessQmDash: false,
          accessEcms: false,
          accessVipr: false,
          accessSdoh: false,
          accessSolutionsCenter: false,
          accessHd: false,
          accessIqb: false,
          accessOrus: false,
          accessConform: false,
          accessFallout: false,
          accessDiretto: false,
          accessHomePs: false,
          accessOperations: false,
          accessPatientOptOut: false,
          accessSdohReporting: false,
          accessOutcomesViewer: false,
          accessPatientSummary: false,
          dataSources: '',
          practiceOids: '',
          tocViewerPerm: '',
          manageAcrsPerm: '',
          providerNpis: '',
          consentForms: '',
          confGroupIds: ''
        },
        allAccess: false,
      };
    default:
      return state;
  }
};

export default addUser;
