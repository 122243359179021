const addFileObject = (fileObject) => {
  return {
    type: 'ADD_FILE_OBJECT',
    payload: fileObject,
  };
};

const removeFileObject = (index) => {
  return {
    type: 'REMOVE_FILE_OBJECT',
    payload: index,
  };
};

const updateDescription = (obj) => {
  return {
    type: 'UPDATE_DESCRIPTION',
    payload: obj,
  };
};

const updateFileProgress = (obj) => {
  return {
    type: 'UPDATE_FILE_PROGRESS',
    payload: obj,
  };
};

const clearFileObjects = () => {
  return {
    type: 'CLEAR_FILE_OBJECTS',
  };
};

const setLastFile = (tf) => {
  return {
    type: 'SET_LAST_FILE',
    lastFile: tf,
  };
};

export default {
  addFileObject,
  removeFileObject,
  updateDescription,
  updateFileProgress,
  clearFileObjects,
  setLastFile,
};
