import React, { useEffect, useRef, useState } from 'react';

import useProgress from 'use-progress';
import useAnalytics from '../../custom-hooks/useAnalytics';

import { Redirect } from 'react-router-dom';

import './Forbidden.css';
import '../../css/use-progress.css';

export default () => {
  useProgress();
  useAnalytics('Forbidden');
  const isMountedRef = useRef(null);
  const [redirect, setRedirect] = useState(false);

  useEffect(() => {
    isMountedRef.current = true;
    console.log('redirect forbidden')
    setTimeout(() => setRedirect(true), 3000);

    return () => (isMountedRef.current = false);
  }, []);

  return redirect ? (
    <Redirect to="/" />
  ) : (
    <div className="Forbidden">
      <h4>You do not have the appropriate permissions to access this page.</h4>
      <p>You will be redirected to the homepage in 5 seconds.</p>
    </div>
  );
};
