const editUser = (
  state = {
    editUserObject: {},
  },
  action
) => {
  switch (action.type) {
    case 'SET_EDIT_USER_OBJECT':
      return {
        ...state,
        editUserObject: action.payload,
      };
    default:
      return state;
  }
};

export default editUser;
