const setUser = (userObj) => {
  return {
    type: 'SET_USER',
    payload: userObj,
  };
};

const setChangePassword = (tf) => {
  return {
    type: 'SET_CHANGE_PASSWORD',
    payload: tf,
  };
};

const setShowSetupMfa = (tf) => {
  return {
    type: 'SET_SHOW_SETUP_MFA',
    payload: tf,
  };
};

const setShowConfirmation = (tf) => {
  return {
    type: 'SET_SHOW_CONFIRMATION',
    payload: tf,
  };
};

const setShowLoginForm = (tf) => {
  return {
    type: 'SET_SHOW_LOGIN_FORM',
    payload: tf,
  };
};

const setSourceOrganization = (org) => {
  return {
    type: 'SET_SOURCE_ORGANIZATION',
    payload: org,
  };
};

export default {
  setUser,
  setChangePassword,
  setShowSetupMfa,
  setShowConfirmation,
  setShowLoginForm,
  setSourceOrganization,
};
