import React, { useState, useContext } from 'react';

import { Modal } from 'react-bootstrap';

import LoaderButton from '../../components/LoaderButton/LoaderButton';

import { AuthContext } from '../../context/AuthContext';

import './IdleTimeoutModal.css';

const IdleTimeoutModal = () => {
  const { user, handleSignout } = useContext(AuthContext);

  const [showModal, setShowModal] = useState(false);
  const [isClosing, setIsClosing] = useState(false);

  let warnTimeout;
  let logoutTimeout;

  const warn = () => {
    if (user) {
      modalOpen();
    }
  };

  const logout = () => {
    if (user) {
      setIsClosing(true);
      modalClose();
      handleSignout();
      setIsClosing(false);
    }
  };

  const setTimeouts = () => {
    warnTimeout = setTimeout(warn, 540 * 1000);
    logoutTimeout = setTimeout(logout, 600 * 1000);
  };

  const clearTimeouts = () => {
    if (warnTimeout) clearTimeout(warnTimeout);
    if (logoutTimeout) clearTimeout(logoutTimeout);
  };

  const events = [
    'load',
    'mousemove',
    'mousedown',
    'click',
    'scroll',
    'keypress',
  ];

  const resetTimeout = () => {
    clearTimeouts();
    setTimeouts();
  };

  for (let i in events) {
    window.addEventListener(events[i], resetTimeout);
  }

  setTimeouts();

  const modalClose = () => {
    setIsClosing(true);
    setShowModal(false);
    setIsClosing(false);
  };

  const modalOpen = () => {
    if (user) {
      setShowModal(true);
    }
  };

  return (
    <div>
      <Modal
        show={showModal}
        onHide={modalClose}
        dialogClassName="IdleTimeoutModal"
        aria-labelledby="Idle Timeout Modal"
      >
        <Modal.Header closeButton>
          <div className="icon-box">
            <i className="material-icons">alarm</i>
          </div>
          <h4 className="modal-title">MIGateway Session Expiring</h4>
        </Modal.Header>
        <Modal.Body className="text-center">
          <p>
            Your MIGateway session will expire in one minute. Please press the
            'OK' button to extend your session.
          </p>
        </Modal.Body>
        <Modal.Footer>
          <LoaderButton
            type="submit"
            isLoading={isClosing}
            text="Continue"
            loadingText=" Closing..."
            onClick={modalClose}
          />
          <LoaderButton
            type="submit"
            isLoading={isClosing}
            text="Logout"
            loadingText=" Logging out..."
            onClick={logout}
          />
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default IdleTimeoutModal;
