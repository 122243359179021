import React from 'react';
import FadeIn from 'react-fade-in';

const SSOExpiredSession = () => {

    return (
        <>
            <FadeIn>
                <div style={{ textAlign: "center", paddingTop: "100px" }}>
                    <div className="icon-box">
                        <i className="material-icons" style={{ color: '#223c89', fontSize: '50px' }}>alarm</i>
                    </div>
                    <h1>Your session has timed out.</h1>
                    <p>Please exit the tab and re-initiate a login via your EHR or IdP.</p>
                </div>
            </FadeIn>
        </>

    );

};

export default SSOExpiredSession; 