const errors = (
  state = {
    errorMessage: null,
    showError: false,
  },
  action
) => {
  switch (action.type) {
    case 'SET_SHOW_ERROR':
      return {
        ...state,
        showError: action.payload,
      };
    case 'SET_ERROR_MESSAGE':
      return {
        ...state,
        errorMessage: action.payload,
      };
    default:
      return state;
  }
};

export default errors;
