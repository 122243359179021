const setPatient = (patientObj) => {
  return {
    type: 'SET_PATIENT',
    payload: patientObj,
  };
};

export default {
  setPatient,
};
