const allAccess = (
  state = {
    allAccess: false,
  },
  action
) => {
  switch (action.type) {
    case 'SET_ALL_ACCESS':
      return {
        ...state,
        allAccess: action.allAccess,
      };
    case 'TOGGLE_ALL_ACCESS':
      return {
        allAccess: !state.allAccess,
      };
    default:
      return state;
  }
};

export default allAccess;
