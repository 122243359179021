const orgAdmin = (
  state = {
    duplicateOrgs: [],
    isConfirming: false,
  },
  action
) => {
  switch (action.type) {
    case 'SET_DUPLICATE_ORGS':
      return {
        ...state,
        duplicateOrgs: action.payload,
      };
    case 'ADD_DUPLICATE_ORGS':
      return {
        ...state,
        duplicateOrgs: [...state.duplicateOrgs, action.duplicateOrgs],
      };
    case 'RESET_DUPLICATE_ORGS':
      return {
        ...state,
        duplicateOrgs: [],
      };
    case 'HANDLE_CONFIRM':
      return {
        ...state,
        isConfirming: action.isConfirming,
      };
    default:
      return state;
  }
};

export default orgAdmin;
