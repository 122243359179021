const setShowError = (tf) => {
  return {
    type: 'SET_SHOW_ERROR',
    payload: tf,
  };
};

const setErrorMessage = (message) => {
  return {
    type: 'SET_ERROR_MESSAGE',
    payload: message,
  };
};

export default {
  setShowError,
  setErrorMessage,
};
