import React, { useContext } from 'react';

import { Route } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { AuthContext } from '../context/AuthContext';
import Forbidden from '../pages/Forbidden/Forbidden';

const AdminRoute = ({ component, ...options }) => {
  const { user } = useContext(AuthContext);

  const isSuperAdmin = useSelector((state) => state.permissions.isSuperAdmin);

  const finalComponent = user && isSuperAdmin ? component : Forbidden;

  return <Route {...options} component={finalComponent} />;
};

export default AdminRoute;
