import React, { useContext, useEffect, useRef, useState } from 'react';

import { Auth } from 'aws-amplify';
import { Modal, Button } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { AuthContext } from '../../context/AuthContext';

// import imgLogo from '../../img/logo.jpg';
import Logo from '../../components/Logo/Logo';

const InitialLoginModal = () => {
  const { user, handleSignout } = useContext(AuthContext);
  const initialLogin = useSelector((state) => state.user.initialLogin);
  const isMountedRef = useRef(null);

  const [showModal, setShowModal] = useState(false);
  // const [initialLogin, setInitialLogin] = useState('');

  const noAccept = () => {
    setShowModal(false);
    handleSignout();
  };

  useEffect(() => {
    isMountedRef.current = true;
    const initialloginaccept = localStorage.getItem('initialloginaccept');

    if (user) {
      if (
        initialLogin == "true" &&
        !initialloginaccept
      ) {
        setShowModal(true);
      }
    }
    return () => (isMountedRef.current = false);
  }, [user, setShowModal]);

  const accept = () => {
    localStorage.setItem('initialloginaccept', true);
    updateInitialLogin();
    setShowModal(false);

    // setShouldShowModal(false);
  };

  const updateInitialLogin = async () => {
    if (user) {
      await Auth.updateUserAttributes(user, { 'custom:initiallogin': 'false' });
    }
  };

  return (
    <>
      {/* Initial login modal */}
      <Modal
        show={showModal}
        onHide={noAccept}
        dialogClassName="modal-65w"
        aria-labelledby="Initial Login Modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <Logo />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">
          <p>
            I understand that my employer has executed all necessary legal
            agreements with Michigan Health Information Network Shared Services
            for access to the MIDIGATE(R) Service.
          </p>

          <p>
            I confirm that I am the administrator designated by my employer to
            access the MIDIGATE(R) Service.
          </p>

          <p>
            <strong>Simple Data Sharing Organization (SDSO)</strong> <br />
            <a
              href="https://mihin.org/wp-content/uploads/2018/12/MIHIN-Master-Use-Case-Agreement-MUCA.pdf"
              rel="noopener noreferrer"
              target="_blank"
            >
              Master Use Case Agreement (MUCA)
            </a>{' '}
            <br />
            Simple Data Sharing Organization Agreement (SDSOA) <br />
            <a
              href="https://mihin.org/wp-content/uploads/2018/12/MiHIN-UCE-Active-Care-Relationship-Service.pdf"
              rel="noopener noreferrer"
              target="_blank"
            >
              Active Care Relationship Service UCE
            </a>{' '}
            <br />
            <a
              href="https://mihin.org/wp-content/uploads/2018/12/MiHIN-UCE-Health-Provider-Directory.pdf"
              rel="noopener noreferrer"
              target="_blank"
            >
              Health Provider Directory UCE
            </a>{' '}
            <br />
            <a
              href="https://mihin.org/wp-content/uploads/2018/12/MiHIN-UCE-Admission-Discharge-Transfer-Notifications-.pdf"
              rel="noopener noreferrer"
              target="_blank"
            >
              Admission, Discharge, Transfer Notification UCE
            </a>{' '}
            <br />
            <a
              href="https://mihin.org/wp-content/uploads/2018/12/MiHIN-UCE-Medication-Reconciliation.pdf"
              rel="noopener noreferrer"
              target="_blank"
            >
              Medication Reconciliation UCE
            </a>{' '}
            or{' '}
            <a
              href="https://mihin.org/wp-content/uploads/2019/06/MiHIN-UCE-Exchange-Consolidated-Clinical-Document-Architecture-.pdf"
              rel="noopener noreferrer"
              target="_blank"
            >
              Exchange C-CDA UCE
            </a>
          </p>

          <p>
            <strong>Qualified Data Sharing Organization (QDSO)</strong> <br />
            <a
              href="https://mihin.org/wp-content/uploads/2018/12/MIHIN-Master-Use-Case-Agreement-MUCA.pdf"
              rel="noopener noreferrer"
              target="_blank"
            >
              Master Use Case Agreement (MUCA)
            </a>{' '}
            <br />
            <a
              href="https://mihin.org/wp-content/uploads/2018/12/MiHIN-Qualified-Organization-Agreement-QDSOA.pdf"
              rel="noopener noreferrer"
              target="_blank"
            >
              Qualified Data Sharing Organization (QDSOA)
            </a>{' '}
            <br />
            <a
              href="https://mihin.org/wp-content/uploads/2018/12/MiHIN-UCE-Active-Care-Relationship-Service.pdf"
              rel="noopener noreferrer"
              target="_blank"
            >
              Active Care Relationship Service UCE
            </a>{' '}
            <br />
            <a
              href="https://mihin.org/wp-content/uploads/2018/12/MiHIN-UCE-Health-Provider-Directory.pdf"
              rel="noopener noreferrer"
              target="_blank"
            >
              Health Provider Directory UCE
            </a>{' '}
            <br />
            <a
              href="https://mihin.org/wp-content/uploads/2018/12/MiHIN-UCE-Admission-Discharge-Transfer-Notifications-.pdf"
              rel="noopener noreferrer"
              target="_blank"
            >
              Admission Discharge, Transfer Notification UCE
            </a>{' '}
            <br />
            <a
              href="https://mihin.org/wp-content/uploads/2018/12/MiHIN-UCE-Medication-Reconciliation.pdf"
              rel="noopener noreferrer"
              target="_blank"
            >
              Medication Reconciliation UCE
            </a>{' '}
            or{' '}
            <a
              href="https://mihin.org/wp-content/uploads/2019/06/MiHIN-UCE-Exchange-Consolidated-Clinical-Document-Architecture-.pdf"
              rel="noopener noreferrer"
              target="_blank"
            >
              Exchange C-CDA UCE
            </a>
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={accept}>Next</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default InitialLoginModal;
