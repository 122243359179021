const setAccessToken = (token) => {
  return {
    type: 'SET_ACCESS_TOKEN',
    payload: token,
  };
};

const setIdToken = (token) => {
  return {
    type: 'SET_ID_TOKEN',
    payload: token,
  };
};

const setRefreshToken = (token) => {
  return {
    type: 'SET_REFRESH_TOKEN',
    payload: token,
  };
};

export default {
  setAccessToken,
  setIdToken,
  setRefreshToken,
};
