import React, { useContext } from 'react';

import Loader from 'react-loader-spinner';

import { Route, Redirect, useLocation } from 'react-router-dom';

import { AuthContext } from '../context/AuthContext';

export default function AuthenticatedRoute({ children, ...rest }) {
  const { pathname, search } = useLocation();
  const { isLoggedIn } = useContext(AuthContext);
  return (
    <Route {...rest}>
      {isLoggedIn ? (
        children
      ) : (isLoggedIn === undefined) === undefined ? (
        <div
          style={{
            position: 'fixed',
            width: '100%',
            top: '50%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            color: '#223c89',
          }}
        >
          <Loader type="ThreeDots" color="#223c89" height="80" width="80" />
        </div>
      ) : (
        <Redirect to={`/login?redirect=${pathname}${search}`} />
      )}
    </Route>
  );
}
