const login = (
  state = {
    user: null,
    changePassword: false,
    showSetupMfa: false,
    showConfirmation: false,
    showLoginForm: true,
    sourceOrganization: process.env.REACT_APP_TEMPLATE,
  },
  action
) => {
  switch (action.type) {
    case 'SET_USER':
      return {
        ...state,
        user: action.payload,
      };
    case 'SET_CHANGE_PASSWORD':
      return {
        ...state,
        changePassword: action.payload,
      };
    case 'SET_SHOW_SETUP_MFA':
      return {
        ...state,
        showSetupMFA: action.payload,
      };
    case 'SET_SHOW_CONFIRMATION':
      return {
        ...state,
        showConfirmation: action.payload,
      };
    case 'SET_SHOW_LOGIN_FORM':
      return {
        ...state,
        showLoginForm: action.payload,
      };
    case 'SET_SOURCE_ORGANIZATION':
      return {
        ...state,
        sourceOrganization: action.payload,
      };
    default:
      return state;
  }
};

export default login;
