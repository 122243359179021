import React, { useEffect, useRef } from "react";

import useProgress from "use-progress";

import { Analytics, Auth } from "aws-amplify";
import { useDispatch, useSelector } from "react-redux";
import { withRouter, useLocation, useHistory } from "react-router-dom";

import Routes from "./routing/Routes";

import { parse } from "query-string";
import useAmplifyAuth from "./custom-hooks/useAmplifyAuth";

import { AuthProvider } from "./context/AuthContext";

import allActions from "./state/actions";

import IdleTimeoutModal from "./modals/IdleTimeoutModal/IdleTimeoutModal";
import InitialLoginModal from "./modals/InitialLoginModal/InitialLoginModal";
import TopNav from "./components/TopNav/TopNav";
import MessageBanner from "./components/MessageBanner/MessageBanner";
import SSOLogin from "./components/SSOLogin/SSOLogin";

import "./css/use-progress.css";

const App = () => {
  const template = process.env.REACT_APP_TEMPLATE;
  const email = useSelector((state) => state.user.email);
  const sub = useSelector((state) => state.user.sub);
  const globalDispatch = useDispatch();
  const isMountedRef = useRef(null);
  const url = window.location.href;
  const location = useLocation();
  let ssoLogin = sessionStorage.getItem("ssoLogin");
  const history = useHistory();

  const handleViprPatient = (payload) => {
    globalDispatch(allActions.viprActions.setPatient(payload));
  };

  const {
    state: { user, isLoggedIn },
    handleSignout,
    handleSSOSignout,
  } = useAmplifyAuth();

  useProgress();

  //check if SSO login
  useEffect(() => {
    isMountedRef.current = true;

    const search = parse(location.search)
    const { context, idp } = search

    if (idp && idp != "") {
      SSOSignIn(idp);
      if (context && context != "") {
        //if patient context was sent, save record ID
        sessionStorage.setItem("ssoContextId", context);
      }
      sessionStorage.setItem("ssoIdpName", idp);
    }

    return () => (isMountedRef.current = false);
  }, []);

  const SSOSignIn = async (idp) => {
    try {
      sessionStorage.setItem("ssoLogin", "true");
      await Auth.federatedSignIn({ provider: idp });
    }
    catch (err) {
      console.log("error with federated auth " + err);
    }
  }

  useEffect(() => {
    isMountedRef.current = true;
    if (user) {
      Analytics.updateEndpoint({
        address: email,
        channelType: "EMAIL",
        optOut: "NONE",
        userId: sub,
      });
    }
    return () => (isMountedRef.current = false);
  });

  useEffect(() => {
    isMountedRef.current = true;
    globalDispatch(allActions.loginActions.setSourceOrganization(template));

    const favicon = document.getElementById("favicon");
    let publicUrl;
    if (url.includes("localhost")) {
      publicUrl = `http://${window.location.hostname}:3142`;
    } else {
      publicUrl = `https://${window.location.hostname}`;
    }
    const stylesheet = document.getElementById("template");
    const stylesheetUrl = process.env.REACT_APP_ASSETS_BUCKET;
    stylesheet.href = `${stylesheetUrl}/${template}/css/theme.css`;
    favicon.href = `${stylesheetUrl}/${template}/images/favicon.ico`;
    if (url.includes(process.env.REACT_APP_UPHIE_URL)) {
      globalDispatch(allActions.loginActions.setSourceOrganization("uphie"));
      favicon.href = `${publicUrl}/uphie_favicon.ico`;
      document.querySelector("title").textContent = "UPHIE";
    }

    return () => (isMountedRef.current = false);
  }, [globalDispatch, template]);

  function parseJwt(token) {
    if (!token) {
      return;
    }
    const base64Url = token.split(".")[1];
    const base64 = base64Url.replace("-", "+").replace("_", "/");
    return JSON.parse(window.atob(base64));
  }

  useEffect(() => {
    isMountedRef.current = true;
    if (user) {
      const setTokens = async () => {
        await Auth.currentSession().then((res) => {
          let accessToken = res.getAccessToken();
          let idToken = res.getIdToken();
          const refreshToken = res.getRefreshToken();
          let accessJwt = accessToken.getJwtToken();
          let idJwt = idToken.getJwtToken();
          const refreshJwt = refreshToken.getToken();
          globalDispatch(allActions.tokenActions.setAccessToken(accessJwt));
          globalDispatch(allActions.tokenActions.setIdToken(idJwt));
          globalDispatch(allActions.tokenActions.setRefreshToken(refreshJwt));
          let tokenData = parseJwt(idJwt);
          if (tokenData["custom:role"] === "admin") {
            globalDispatch(allActions.permissionsActions.setIsAdmin(true));
          }
          if (tokenData["custom:role"] === "super admin") {
            globalDispatch(allActions.permissionsActions.setIsSuperAdmin(true));
          }
          if (tokenData["custom:role"] === "org admin") {
            globalDispatch(allActions.permissionsActions.setIsOrgAdmin(true));
          }
          if (tokenData["custom:role"] === "data steward") {
            globalDispatch(
              allActions.permissionsActions.setIsDataSteward(true)
            );
          }
          if (tokenData["custom:role"] === "org admin") {
            globalDispatch(allActions.permissionsActions.setIsOrgAdmin(true));
          }
          globalDispatch(
            allActions.permissionsActions.setPermissions({
              accessTocViewer: JSON.parse(
                tokenData["custom:access_toc_viewer"]
                  ? tokenData["custom:access_toc_viewer"]
                  : "false"
              ),
              accessManageAcrs: JSON.parse(
                tokenData["custom:access_manage_acrs"]
                  ? tokenData["custom:access_manage_acrs"]
                  : "false"
              ),
              accessFileSub: JSON.parse(
                tokenData["custom:access_file_sub"]
                  ? tokenData["custom:access_file_sub"]
                  : "false"
              ),
              accessQmDash: JSON.parse(
                tokenData["custom:access_qm_dash"]
                  ? tokenData["custom:access_qm_dash"]
                  : "false"
              ),
              accessEcms: JSON.parse(
                tokenData["custom:access_ecms"]
                  ? tokenData["custom:access_ecms"]
                  : "false"
              ),
              accessVipr: JSON.parse(
                tokenData["custom:access_vipr"]
                  ? tokenData["custom:access_vipr"]
                  : "false"
              ),
              accessSdoh: JSON.parse(
                tokenData["custom:access_sdoh"]
                  ? tokenData["custom:access_sdoh"]
                  : "false"
              ),
              accessSolutionsCenter: JSON.parse(
                tokenData["custom:access_solutions_c"]
                  ? tokenData["custom:access_solutions_c"]
                  : "false"
              ),
              accessHd: JSON.parse(
                tokenData["custom:access_hd"]
                  ? tokenData["custom:access_hd"]
                  : "false"
              ),
              accessIqb: JSON.parse(
                tokenData["custom:access_iqb"]
                  ? tokenData["custom:access_iqb"]
                  : "false"
              ),
              accessOrus: JSON.parse(
                tokenData["custom:access_orus"]
                  ? tokenData["custom:access_orus"]
                  : "false"
              ),
              accessConform: JSON.parse(
                tokenData["custom:access_adt_conform"]
                  ? tokenData["custom:access_adt_conform"]
                  : "false"
              ),
              accessFallout: JSON.parse(
                tokenData["custom:access_adt_fallout"]
                  ? tokenData["custom:access_adt_fallout"]
                  : "false"
              ),
              accessDiretto: JSON.parse(
                tokenData["custom:access_diretto"]
                  ? tokenData["custom:access_diretto"]
                  : "false"
              ),
              accessHomePs: JSON.parse(
                tokenData["custom:access_home_ps"]
                  ? tokenData["custom:access_home_ps"]
                  : "false"
              ),
              accessOperations: JSON.parse(
                tokenData["custom:access_operations"]
                  ? tokenData["custom:access_operations"]
                  : "false"
              ),
              accessSdohReporting: JSON.parse(
                tokenData["custom:access_sdoh_reporting"]
                  ? tokenData["custom:access_sdoh_reporting"]
                  : "true"
              ),
              accessOutcomesViewer: JSON.parse(
                tokenData["custom:access_outcomes_viewer"]
                  ? tokenData["custom:access_outcomes_viewer"]
                  : "true"
              ),
              accessPatientSummary: JSON.parse(
                tokenData["custom:access_patient_summary"]
                  ? tokenData["custom:access_patient_summary"]
                  : "true"
              ),
            })
          );
          globalDispatch(
            allActions.permissionsActions.setOrgName(
              tokenData["custom:org_name"] ? tokenData["custom:org_name"] : ""
            )
          );
          globalDispatch(
            allActions.permissionsActions.setLaunchId(
              tokenData["launch_id"] ? tokenData["launch_id"] : ""
            )
          );
          globalDispatch(
            allActions.userActions.setGivenName(
              tokenData["given_name"] ? tokenData["given_name"] : ""
            )
          );
          globalDispatch(
            allActions.userActions.setEmail(
              tokenData["email"] ? tokenData["email"] : ""
            )
          );
          globalDispatch(
            allActions.userActions.setSub(
              tokenData["sub"] ? tokenData["sub"] : ""
            )
          );
          globalDispatch(
            allActions.userActions.setInitialLogin(
              tokenData["custom:initiallogin"] ? tokenData["custom:initiallogin"] : "false"
            )
          );
        })
          .catch((err) => {
            console.log("error setting tokens " + err)
          });
      };
      setTokens();
    }

    return () => (isMountedRef.current = false);
  }, [user, globalDispatch]);

  //Listens for a call to launch Outcome from PV, MACRS, or TOCV
  useEffect(() => {
    sessionStorage.setItem("newPatientLoad", "true");
    window.addEventListener('message', receiveMessage, false);

    function receiveMessage(event) {
      if (event.origin !== process.env.REACT_APP_PATIENT_VIEWER_URL) {
        console.log("The event origin is not authorized to make this request");
        console.log(event.origin);
        return;
      }
      if (event.data.action === 'load-outcomes') {
        history.push('/outcomesviewer')
      }

    }

  }, []);

  return (
    <>
      <AuthProvider
        value={{
          user,
          handleSignout,
          handleSSOSignout,
          isLoggedIn,
          handleViprPatient,
        }}
      >
        <>
          <div
            style={{ height: "100%" }}
            className={
              url.includes(process.env.REACT_APP_UPHIE_URL)
                ? "uphie-main-wrap"
                : "main-wrap"
            }
          >
            {(ssoLogin === "true" && !isLoggedIn) && <SSOLogin />}
            <TopNav />
            <Routes />
            {user && <IdleTimeoutModal />}
            <InitialLoginModal />
          </div>
        </>
      </AuthProvider>
    </>
  );
};

export default withRouter(App);
