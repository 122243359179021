const success = (
  state = {
    successMessage: null,
    showSuccess: false,
  },
  action
) => {
  switch (action.type) {
    case 'SET_SHOW_SUCCESS':
      return {
        ...state,
        showSuccess: action.payload,
      };
    case 'SET_SUCCESS_MESSAGE':
      return {
        ...state,
        successMessage: action.payload,
      };
    default:
      return state;
  }
};

export default success;
