import addUser from './addUser';
import allAccess from './allAccess';
import dropzone from './dropzone';
import editUser from './editUser';
import errorHandling from './errorHandling';
import errors from './errors';
import fileObjects from './fileObjects';
import login from './login';
import modals from './modals';
import orgAdmin from './orgAdmin';
import patientContext from './patientContext';
import permissions from './permissions';
import success from './success';
import tokens from './tokens';
import viprPatient from './viprPatient';
import user from './user';
import { combineReducers } from 'redux';

const rootReducer = combineReducers({
  addUser,
  allAccess,
  dropzone,
  editUser,
  errorHandling,
  errors,
  fileObjects,
  login,
  modals,
  orgAdmin,
  patientContext,
  permissions,
  success,
  tokens,
  viprPatient,
  user,
});

export default rootReducer;
