import React, { useRef } from 'react';

import LazyLoad from 'react-lazyload';
import styled, { keyframes } from 'styled-components';

import uphieLogo from '../../img/uphie_logo_small.png';

const ImageWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 110px;
`;

const loadingAnimation = keyframes`
  0% {
    background-color: #fff;
  }
  50% {
    background-color: #ccc;
  }
  100% {
    background-color: #fff;
  }
`;

const Placeholder = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  animation: ${loadingAnimation} 1s infinite;
`;

const StyledImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const Logo = () => {
  const template = process.env.REACT_APP_TEMPLATE;
  const refPlaceholder = useRef();
  const url = window.location.href;
  const stylesheetUrl = process.env.REACT_APP_ASSETS_BUCKET;

  const removePlaceholder = () => {
    refPlaceholder.current.remove();
  };
  return (
    <ImageWrapper>
      <Placeholder ref={refPlaceholder} />
      <LazyLoad>
        {url.includes(process.env.REACT_APP_UPHIE_URL) ? (
          <StyledImage
            onLoad={removePlaceholder}
            onError={removePlaceholder}
            src={uphieLogo}
            alt={`uphie logo`}
          />
        ) : (
          <StyledImage
            onLoad={removePlaceholder}
            onError={removePlaceholder}
            src={`${stylesheetUrl}/${template}/images/logo_small.png`}
            alt={`${template} logo`}
          />
        )}
      </LazyLoad>
    </ImageWrapper>
  );
};

export default Logo;
