const setAddUserObject = (userObj) => {
  return {
    type: 'SET_ADD_USER_OBJECT',
    payload: userObj,
  };
};

const resetAddUserObject = () => {
  return {
    type: 'RESET_ADD_USER_OBJECT',
  };
};

export default {
  resetAddUserObject,
  setAddUserObject,
};
