const setAllAccess = (tf) => {
  return {
    type: 'SET_ALL_ACCESS',
    allAccess: tf,
  };
};

const toggleAllAccess = () => {
  return {
    type: 'TOGGLE_ALL_ACCESS',
  };
};

export default {
  setAllAccess,
  toggleAllAccess,
};
